<template>
  <div class="jslist-style">
    <titles :title="$t('text.t389')" />
    <div class="box1 start-center">
      <div class="chose center-center" @click="show = true">
        <div class="cr">{{ names }}</div>
        <van-icon name="arrow-down" color="#000" size="14" />
      </div>
    </div>
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
        ref="vanList"
      >
        <div class="item between-center" v-for="item in list" :key="item.id" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
          <div class="left">
            <div class="row1 start-center">
              <div class="t1">#{{item.tokenid}}</div>
              <div class="t3" v-if="item.ismine">{{$t('text.t14')}}</div>
              <div class="t2" v-else>{{$t('text.t406')}}</div>
            </div>
            <div class="row2">{{$parseTime(item.createtime)}}</div>
          </div>
          <div class="right end-center">
            <div class="rout">
              <div>{{item.price}}</div>
              <div class="rout-t1">{{$t('text.t407')}}</div>
            </div>
            <van-icon name="arrow" color="#909090" size="20" />
          </div>
        </div>
      </van-list>
    </div>
    <van-action-sheet
      :cancel-text="$t('text.t52')"
      close-on-click-action
      closeable
      v-model="show"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>

<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components: {
    titles,
  },
  data() {
    return {
      show: false,
      actions: [
        { name: this.$t('text.t408'), type: 0 },
        { name: this.$t('text.t409'), type: 1 },
        { name: this.$t('text.t410'), type: 2 },
        { name: this.$t('text.t411'), type: 3 },
      ],
      names: "",
      type: "",
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      chain: "",
      contract: "",
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  created() {
    const { chain, contract } = this.$route.query;
    this.chain = chain;
    this.contract = contract;
    this.onSelect(this.actions[0]);
  },
  methods: {
    onSelect(item) {
      this.names = item.name;
      this.type = item.type;
      this.clearList()
    },
    clearList(){
      this.page = 1
      this.limit = 20
      this.loading = false
      this.finished = false
      this.list = []
      this.$refs.vanList.check()
    },
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        type: this.type,
        contract: this.contract,
        chainType: this.chain,
        language: this.language,
      };
      this.$http.get("/core/allNumber", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        if(data.length){
          const uid = this.$local.get('shop:uid')
          for (let i = 0; i < data.length; i++) {
            data[i].ismine = uid == data[i].user_id ? true : false
          }
        }
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.jslist-style {
  .list {
    .item {
      border-bottom: 1px solid #e1e1e1;
      padding: 18px 20px 13px;
      .left {
        .row1 {
          margin-bottom: 12px;
          .t1 {
            font-size: 15px;
            font-weight: bold;
            color: #323232;
            margin-right: 5px;
          }
          .t2 {
            background: #ffbaeb;
            border-radius: 5px;
            padding: 3px 9px;
            color: #e233e0;
          }
          .t3{
            background: #FFF4C0;
            border-radius: 5px;
            padding: 3px 9px;
            color: #FFDB33;
          }
        }
        .row2 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .right {
        .rout{
          text-align: right;
          font-size: 15px;
          font-weight: bold;
          color: #323232;
          margin-right: 10px;
          .rout-t1{
            font-size: 12px;
            font-weight: 500;
            color: #909090;
            margin-top: 11px;
          }
        }
      }
    }
  }
  .box1 {
    padding: 10px 20px 0;
    .chose {
      background: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 35px;
      padding: 9px 16px;
      font-size: 12px;
      font-weight: 500;
      color: #323232;
      .cr {
        margin-right: 10px;
      }
    }
  }
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #323232;
  border-color: #323232;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #323232;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #323232;
}
</style>